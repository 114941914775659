.cursor {
width: 15px;
height: 15px;
margin: 0;
padding: 0;
/* background-color: rgb(164, 168, 244); */
position: fixed;
pointer-events: none;
border-radius: 50%;
transform: translate(-50%, -50%);
}

.img-cont{
  position: absolute;
  /* width: 300px;   translate-y-[17rem]  scale-[1.7]*/
  transform: translate(100px) scale(0.8);
  opacity:0;
  width: 30%;
  transition: 1s ease-in-out;
}
