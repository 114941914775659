body{
-webkit-font-feature-smoothing: antialiased ;
background-image: repeating-linear-gradient(67.5deg, hsla(145,0%,97%,0.2) 0px, hsla(145,0%,97%,0.2) 0px,transparent 0px, transparent 1px,hsla(145,0%,97%,0.2) 1px, hsla(145,0%,97%,0.2) 4px,transparent 4px, transparent 5px,hsla(145,0%,97%,0.2) 5px, hsla(145,0%,97%,0.2) 8px),repeating-linear-gradient(180deg, hsla(145,0%,97%,0.2) 0px, hsla(145,0%,97%,0.2) 0px,transparent 0px, transparent 1px,hsla(145,0%,97%,0.2) 1px, hsla(145,0%,97%,0.2) 4px,transparent 4px, transparent 5px,hsla(145,0%,97%,0.2) 5px, hsla(145,0%,97%,0.2) 8px),linear-gradient(315deg, rgba(237,237,237, 0.35),rgba(26,117,135, 0.25));
}

/* 3D4057
747677
D00000
EBFCFB
E1BC29 */

* {
  font-family: 'Castoro Titling', cursive;
}
.App 
{
  text-align: center;
  overflow: hidden;
}

.header, 
.titleText,
.policeTech
{
  font-family: 'Castoro', serif;
}


.mItems{
  background-image: linear-gradient(359deg, rgb(255,255,255),rgb(185,195,203));
}

.navbar{
  background-image: linear-gradient(182deg, #d4e2ee,#1a758743,rgb(255,255,255));
}

.bgPbi
{
  /* background-image: linear-gradient(0deg, rgba(35,127,141, 0.15) 0%,rgba(159, 153, 81, 0.15) 100%),linear-gradient(90deg, rgb(5, 10, 46),rgb(143,143,147)); */
  background-image: radial-gradient(circle 5px at 67% 49%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 5px at 15% 85%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 5px at 26% 60%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 5px at 24% 96%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 5px at 26% 73%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 10px at 85% 15%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 10px at 19% 63%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 10px at 12% 68%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 10px at 72% 48%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle 10px at 48% 31%, rgba(43,43,43, 0.19) 0%, rgba(43,43,43, 0.19) 50%,transparent 50%, transparent 100%),radial-gradient(circle at center right, rgba(160,156,156, 0.26) 0%, rgba(160,156,156, 0.26) 14%,transparent 14%, transparent 86%),linear-gradient(298deg, rgb(234,255,243),#507345d6);
}

.mainEvolution{
  background-image: linear-gradient(359deg, rgb(255,255,255),rgb(185,195,203));
}

.buttonContact,
.productBanner
{
  /* background-image: linear-gradient(360deg, rgba(15,189,209, 0.57),rgb(66,72,255)); */
  /* background-image: linear-gradient(91deg, rgba(221,227,203, 0.3) 0%,rgba(255,255,255, 0.3) 100%),linear-gradient(181deg, #0e869a,rgb(255,255,255)); */
  background-image: radial-gradient(circle at center center, rgba(14,134,154, 0.48),rgba(47,129,239, 0));
  /* background-image: linear-gradient(360deg, rgba(15,189,209, 0.57),rgba(84,4,168, 0.5882352941176471)); */
  /* background-image: repeating-linear-gradient(234deg, rgba(0,0,0,0.09),rgba(0,0,0,0.1),rgba(0,0,0,0.1),rgba(0,0,0,0.04),rgba(0,0,0,0.03),rgba(0,0,0,0.04),rgba(0,0,0,0.02),rgba(0,0,0,0.04),rgba(0,0,0,0.06),rgba(0,0,0,0.1),rgba(0,0,0,0.02),rgba(0,0,0,0.05),rgba(0,0,0,0.04),rgba(0,0,0,0.03),transparent 3px),linear-gradient(189deg, rgb(128,149,255),rgb(14, 252, 246)); */
}

/* #################- Tech Global -############################### */
#techGlobal
{
  background-image: repeating-linear-gradient(67.5deg, hsla(145,0%,97%,0.2) 0px, hsla(145,0%,97%,0.2) 0px,transparent 0px, transparent 1px,hsla(145,0%,97%,0.2) 1px, hsla(145,0%,97%,0.2) 4px,transparent 4px, transparent 5px,hsla(145,0%,97%,0.2) 5px, hsla(145,0%,97%,0.2) 8px),repeating-linear-gradient(180deg, hsla(0, 0%, 97%, 0.079) 0px, hsla(0, 0%, 97%, 0.096) 0px,transparent 0px, transparent 1px,hsla(0, 0%, 97%, 0.058) 1px, hsla(145,0%,97%,0.2) 4px,transparent 4px, transparent 5px,hsla(0, 0%, 97%, 0.189) 5px, hsla(0, 0%, 97%, 0.047) 8px),linear-gradient(315deg, rgba(237, 237, 237, 0.2),rgba(26, 117, 135, 0.31));
  /* background-image: repeating-linear-gradient(90deg, rgba(151, 81, 221,0.5) 0px, rgba(151, 81, 221,0.5) 20px,transparent 20px, transparent 40px),repeating-linear-gradient(0deg, rgba(151, 81, 221,0.5) 0px, rgba(151, 81, 221,0.5) 20px,transparent 20px, transparent 40px),linear-gradient(90deg, #ffffff,#ffffff); */
  /* background-image: repeating-linear-gradient(306deg, rgba(0,0,0,0.04),rgba(0,0,0,0.08),rgba(0,0,0,0.05),rgba(0,0,0,0.04),rgba(0,0,0,0.01),rgba(0,0,0,0.1),rgba(0,0,0,0.09),rgba(0,0,0,0.1),rgba(0,0,0,0.03),transparent,rgba(0,0,0,0.07),rgba(0,0,0,0.03),rgba(0,0,0,0.06) 4px),linear-gradient(351deg, rgba(32,218,233, 0.23137254901960785),rgb(36,59,74)); */
  /* background-image: repeating-linear-gradient(135deg, rgba(0,0,0, 0.03),rgba(0,0,0, 0.03),rgba(0,0,0, 0.03),transparent,rgba(0,0,0, 0.03),rgba(0,0,0, 0.03),rgba(0,0,0, 0.03),rgba(0,0,0, 0.03),rgba(0,0,0,0.02),rgba(0,0,0, 0.03),rgba(0,0,0,0.04),rgba(0,0,0,0.02),rgba(50,227,136, 0.14) 3px),linear-gradient(90deg, rgb(13,2,33),rgba(50,227,136, 0.14)); */
  /* background-image: repeating-linear-gradient(310deg, rgba(35,127,141, 0.15) 0px, rgba(159, 153, 81, 0.15) 2px,transparent 2px, transparent 4px),linear-gradient(355deg, rgb(5, 10, 46),rgb(143,143,147)); */
}

/* #################- Techcard -############################### */

/* #243B4A
#20DAE93B */
.bgPolygone
{
  background-image: linear-gradient(244deg, rgba(32,218,233, 0.23),rgb(36,59,74));
  clip-path: polygon(0 0%, 100% 34%, 100% 100%, 0% 66%);
}

/* #################- Underline Navbar -############################### */
.underlined
{
  display: inline;
  position: relative;
}

.underlined::after
{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: black;
    transform: scale(0);
    transition: transform 0.2s ease-in-out;
}

.underlined:hover::after
{
  transform: scale(1);
}

/* #################- Underline 404 -############################### */

.underlinedNf
{
  display: inline;
  position: relative;
}

.underlinedNf::after
{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 20px;
    background: rgb(255, 255, 255);
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
}

.underlinedNf:hover::after
{
  transform: scale(.07);
}

/* ###############- Grayscale carousel -################ */

.grayscale-carousel img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  }
  .grayscale-carousel img:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  }


/* ###############- TITLE -################ */

.title
{
  padding-top: 130px;
  background-image: linear-gradient(91deg, rgba(221,227,203, 0.3) 0%,rgba(255,255,255, 0.3) 100%),linear-gradient(181deg, rgb(14,134,154),rgb(255,255,255));
  clip-path: polygon(0 0%, 100% 34%, 100% 100%, 0% 66%);
}



.logoAccueil{
  border-radius: 50%;
}

/* ###############- COULEUR CARD PLAN ACTION -################ */

.expClient{
  background-image: linear-gradient(359deg, rgb(188,237,251),rgb(220, 231, 236));
}
 
.diagData{
  background-image: linear-gradient(359deg, rgb(108,192,241),rgb(188,237,251));
}

.propSolution{
  background-image: linear-gradient(359deg, rgb(42,142,220),rgb(108,192,241));
}

.readapt{
  background-image: linear-gradient(359deg, rgba(63, 108, 255, 0.776),rgb(42,142,220));
}

.propDevis{
  background-image: linear-gradient(359deg, rgba(11, 22, 140, 0.719),rgb(42,142,220));
}

.prod{
  background-image: linear-gradient(359deg, #044469,rgb(30, 24, 189));
}

.def{
  background-image: linear-gradient(359deg, #07314a,#126a9de0);
}
/* ###############- POURQUOI DBIS -################ */

@media (min-width: 588px) {
  .bgImg {
    background-image: repeating-linear-gradient(
        67.5deg,
        hsla(145, 0%, 97%, 0.2) 0px,
        hsla(145, 0%, 97%, 0.2) 0px,
        transparent 0px,
        transparent 1px,
        hsla(145, 0%, 97%, 0.2) 1px,
        hsla(145, 0%, 97%, 0.2) 4px,
        transparent 4px,
        transparent 5px,
        hsla(145, 0%, 97%, 0.2) 5px,
        hsla(145, 0%, 97%, 0.2) 8px
      ),
      repeating-linear-gradient(
        180deg,
        hsla(145, 0%, 97%, 0.2) 0px,
        hsla(145, 0%, 97%, 0.2) 0px,
        transparent 0px,
        transparent 1px,
        hsla(145, 0%, 97%, 0.2) 1px,
        hsla(145, 0%, 97%, 0.2) 4px,
        transparent 4px,
        transparent 5px,
        hsla(145, 0%, 97%, 0.2) 5px,
        hsla(145, 0%, 97%, 0.2) 8px
      ),
      linear-gradient(
        315deg,
        rgba(237, 237, 237, 0.35),
        rgba(26, 117, 135, 0.25)
      );
  }
}